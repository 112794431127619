<template>
  <v-row justify="center">
    <!-- Filters -->
    <v-col dense cols="12" class="py-0">
      <v-subheader>Search Ingredients</v-subheader>
      <!--
      <v-subheader>Filters</v-subheader>
      <v-chip-group multiple
        v-model="filters"
        active-class="deep-purple--text text--accent-4"
      >
        <v-chip value="type" filter outlined>Type <v-icon right small>mdi-test-tube</v-icon></v-chip>
        <v-chip value="search" filter outlined>Search <v-icon right small>mdi-test-tube</v-icon></v-chip>
      </v-chip-group>
      -->
    </v-col>

    <v-col cols="12" class="py-0 align-center">
      <v-row dense>
        <v-text-field class="align-center rounded-lg"
          clearable color="success"
          clear-icon="mdi-close-circle-outline"
          prepend-inner-icon="mdi-magnify"
          v-model="inventorySearch"
          label="Search for Ingredients"
          outlined dense
        ></v-text-field>
        <v-btn @click="filterDialog = !filterDialog" height="40px" class="mx-3 rounded-lg" color="success" outlined icon tile>
          <v-icon>mdi-tune</v-icon>
        </v-btn>
      </v-row>
    </v-col>

    <v-col cols="11">
      <v-divider></v-divider>
    </v-col>

    <v-col cols="12" class="text-center">
      <v-btn rounded color="success elevation-0" to="/inventory/add">
        Add Ingredient
        <v-icon right>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-col>

    <!-- Ingredient Cards -->
    <template v-for="item in storeInventory">
      <v-col
        v-if="matchesSearchTerms(item)"
        :key="item.id" cols="12" xs="12" md="4" sm="6"
      >
          <v-card dark class="fill-height rounded-xl d-flex align-content-space-between flex-wrap" style="flex-direction: column;" :color="getIngredientColor(item.type)">
            <v-toolbar class="flex-grow-0" style="text-overflow: ellipsis;" :color="getIngredientColor(item.type)" flat dark>
              <v-toolbar-title>{{ (item.distiller == "" || item.distiller == null || item.distiller === undefined) ? `${item.maker}` : `${item.distiller} - ${item.maker}`}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon :to="`/inventory/${item.id}/edit`"><v-icon>mdi-pencil</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-auto">
              <p class="text-h6" style="font-size: 1rem !important;">
                {{ `${ item.subvariant ||  ''} ${item.variant || ''} ${item.subtype || ''} ${item.type || ''}`.trim() }}
              </p>
              <p class="text-subtitle-2">Qty Left</p>
              <div class="text-center">
                <v-progress-circular :color="'white'" :rotate="-90" size="100" width="15" :value="percentLeft(item)">{{percentLeft(item)}}%</v-progress-circular>
                <br/><br/>
                <p class="">{{item.qty+''+item.measurement+' left'}}</p>
              </div>
            </v-card-text>
            <v-card-actions class="mt-auto" height="52px">
              <v-spacer></v-spacer>
              <v-btn rounded text color="deep-purple accent-4" :to="`/inventory/${item.id}`">
                Details
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-col>
    </template>
    <v-bottom-sheet v-model="filterDialog">
      <v-sheet class="px-4 py-4 rounded-xl">
        <v-row dense>
          <v-col cols="12">
            <v-subheader>Types</v-subheader>
          </v-col>

          <v-col cols="12" class="my-0">
            <v-chip-group multiple
              v-model="ingredientFilter"
              active-class="deep-purple--text text--accent-4"
            >
              <v-chip v-for="item in types" :key="item" :disabled="ingredientCountByType(item) == 0"
                :value="item.toLowerCase()"
                filter outlined
              >
                {{item}}
              </v-chip>
            </v-chip-group>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-btn class="info rounded-lg" @click="filterDialog = false">Show Results</v-btn>
            <v-btn text @click="clearFilters">Clear</v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </v-row>
</template>
<script>
  import { mapState } from 'vuex'
  import { DB } from '../firebase/db.js'
  import { Auth } from '../firebase/auth.js'

  export default {
    name: 'Inventory',
    data: () => ({
      filters: [],
      inventory: [],
      inventorySearch: '',
      ingredientFilter: [],
      types: ['Whiskey', 'Brandy', 'Vodka', 'Rum', 'Gin', 'Tequila', 'Liqueur', 'Other', 'All'],
      filterDialog: false,
    }),
    methods: {
      clearFilters(){
        this.inventorySearch = '';
        this.ingredientFilter = [];
      },
      percentLeft(item){
        var bottleSize = item.originalQty || 750;
        return Math.round((item.qty / bottleSize)*100);
      },
      matchesSearchTerms(item){
        var filterAndSearchParams = []

        // If filtering by type, put in the parameters
        if(this.ingredientFilter.length >= 1){
          filterAndSearchParams = filterAndSearchParams.concat(this.ingredientFilter)
        }

        // If filtering by search, bring in search term
        if(this.inventorySearch && this.inventorySearch.length >= 1){
          filterAndSearchParams.push(this.inventorySearch);
        }

        // console.log(filterAndSearchParams)

        // inclde synonyms
        if( filterAndSearchParams.includes('whiskey') ){
          filterAndSearchParams.push('whisky')
        } else if(filterAndSearchParams.includes('rum')){
          filterAndSearchParams.push('rhum')
        }

        if(this.ingredientFilter.includes('all')){
          return true;
        }

        var searchRegex = new RegExp(`(${filterAndSearchParams.join('|')})`, 'i');

        var itemMatches = false;

        for(const [key, value] of Object.entries(item)){
          if(searchRegex.test(value)){
            itemMatches = itemMatches || true;
          } else {
            itemMatches = itemMatches || false;
          }
        }

        return itemMatches;

        // if( searchRegex.test(item.maker) ||  searchRegex.test(item.type) || searchRegex.test(item.variant) || searchRegex.test(item.subtype || '') || searchRegex.test(item.subvariant || '')){
        //   return true;
        // } else {
        //   return false;
        // }
      },
      getIngredientColor(ingredientType){
        let type = ingredientType.toLowerCase();

        switch(type){
          case 'whiskey':
            return 'yellow darken-3';
            break;
          case 'whisky':
            return 'yellow darken-3';
            break;
          case 'brandy':
            return 'red';
            break;
          case 'vodka':
            return 'brown';
            break;
          case 'rum':
            return 'deep-orange';
            break;
          case 'gin':
            return 'green';
            break;
          case 'tequila':
            return 'light-green';
            break;
          case 'liqueur':
            return 'teal';
            break;
          default:
            return 'blue-grey';
        }
      },
      ingredientCountByType(type){
        return this.storeInventory.reduce( (acc, item) => {
          if((item.type.toLowerCase() == 'whisky' || item.type.toLowerCase() == 'whiskey') && type == 'whiskey'){
            return acc +1;
          } else if(item.type.toLowerCase() == type.toLowerCase()){
            return acc + 1;
          } else {
            return acc
          }
        }, 0)
      }
    },
    computed: mapState({
      storeInventory: 'inventory'
    }),
    mounted(){

      // If types filter is in the URL
      if(this.$route.query.types != null){

        // first, decode the query parameter
        let selectedFilterTypes = decodeURI(this.$route.query.types)
        console.log(selectedFilterTypes)

        // if there's a comma in the query parameters
        if(selectedFilterTypes.indexOf(',') >= 0){

          // Split the parameter
          selectedFilterTypes = selectedFilterTypes.split(',')

          // for each item, do the following
          selectedFilterTypes.forEach( item => {

            // If what was passed was a valid type, push it
            if(this.types.some( type => type.toLowerCase() == item.toLowerCase())){
              if(item.toLowerCase() === 'whisky') {
                this.ingredientFilter.push('whiskey')
                // this.ingredientFilter.push(item)
              } else {
                this.ingredientFilter.push(item)
              }
            }
          });

          // Push the type filter
          this.filters.push('type');

        } else {
          if(this.types.some( type => type.toLowerCase() == selectedFilterTypes.toLowerCase())){
            if(selectedFilterTypes.toLowerCase() == 'whisky'){
              // this.ingredientFilter.push(selectedFilterTypes)
              this.ingredientFilter.push('whiskey')
            } else {
              this.ingredientFilter.push(selectedFilterTypes)
            }

            this.filters.push('type');
          }
        }
      }
      // let currentUser = Auth.currentUser

      // DB.collection('ingredients').where('ownerId', '==', currentUser.uid)
      //   .get()
      //   .then( (querySnapshot) => {
      //     const ingredients = querySnapshot.docs.map( ingredient => ingredient.data())
      //     ingredients.forEach( item => {
      //       this.inventory.push(item)
      //     })
      //   })
    },
  }
</script>